<template>
    <div class="empty-box">
        <img src="@/assets/empty.svg" alt="">
        <span>没有数据~</span>
    </div>
</template>
<style>
.empty-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    span {
        color: #333333;
        font-size: 16px;
    }
    img{
        transform: scale(0.7);
    }
}
</style>
